@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700;800;900;1000&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
    @apply dark:bg-neutral-900 dark:text-white p-5 min-h-screen;
}

/* generic input & button */

label, label + span, label + div > span, select{
    @apply text-gray-900 dark:text-white 
}

.btn-primary > svg, #arrow > svg{
    @apply fill-gray-900 dark:fill-white 
}

input,
button {
    @apply focus:ring-indigo-500 focus:ring-offset-indigo-100 focus:ring-2 focus:ring-offset-2 dark:text-white dark:placeholder-white;
}

.btn-primary {
    @apply flex items-center justify-center py-2 px-4 gap-1 w-fit text-center text-base font-semibold rounded-lg transition ease-in duration-200 hover:bg-gray-700 hover:text-white border-2 border-gray-900 dark:border-gray-100;
}

.btn-secondary {
    @apply flex items-center p-4 rounded-full transition ease-in duration-200 hover:bg-gray-800 hover:text-white border-2 border-gray-900 dark:border-gray-100;
}

.input-primary {
    @apply rounded-lg flex-1 w-fit py-2 px-4 placeholder-gray-600 text-base appearance-none border-2 border-gray-900 dark:border-gray-100 dark:bg-neutral-700;
}

.input-secondary {
    @apply  rounded-lg flex-1 w-full py-2 px-4 placeholder-gray-600 text-base appearance-none border-2 border-gray-900 dark:border-gray-100 dark:bg-neutral-700;
}

.select-primary {
    @apply flex items-center px-6 py-2 h-fit transition ease-in duration-200 rounded-lg border-2 border-gray-900 dark:border-gray-100 dark:bg-neutral-700;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  
   @apply invert-0 dark:invert-[1]
  /*   filter: invert(1); */
}
/* modal */
.ReactModal__Overlay--after-open{
    @apply fixed inset-0 bg-neutral-200/75 dark:bg-neutral-600/75 !important;
}

.ReactModal__Content, .ReactModal__Content--after-open{
    @apply bg-white dark:bg-neutral-800
}


/* scrollbar */
/* .ReactModalPortal ::-webkit-scrollbar {
    @apply appearance-none w-2 ;
}

.ReactModalPortal ::-webkit-scrollbar-track {
    @apply bg-white dark:bg-neutral-800
}

.ReactModalPortal ::-webkit-scrollbar-thumb {
    @apply bg-neutral-800 dark:bg-white  rounded-full
} */

/* progress bar */

progress {
    @apply h-5 border-2 rounded-full border-gray-900 dark:border-white;
}

::-webkit-progress-bar {
    @apply bg-white dark:bg-gray-900 rounded-full;
}

::-webkit-progress-value {
    @apply bg-gray-800 dark:bg-white rounded-full;
}

.progress-red::-webkit-progress-value {
    @apply bg-red-400 rounded-full;
}

.progress-yellow::-webkit-progress-value {
    @apply bg-yellow-400 rounded-full;
}


#ProductList tr:last-child td{
 @apply  border-b-0 
}

/* .div-productUpdate{
    @apply my-2 px-4 py-5 bg-gray-50 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6 w-full items-center
} */